html,
body {
  background-color: #010c19;
  margin: 0px !important;
  scroll-behavior: smooth;
}

h2 {
  color: #d8e0f3;
  font-weight: 600 !important;
}

.altFont,
.altFont * {
  font-family: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace !important;
}

.hvr-icon {
  -o-transition: all 300ms ease !important;
  -moz-transition: all 300ms ease !important;
  -webkit-transition: all 300ms ease !important;
  transition: all 300ms ease !important;
}

.hvr-icon:hover {
  opacity: 0.5;
}

.fade-in-section {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
