/* BLINKING CURSON ANIMATION */

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.skills {
  display: flex;
  align-items: center;
  gap: 2px;
}

.skills::after {
  content: '';
  width: 5px;
  height: 20px;
  background: #34a92c;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}
