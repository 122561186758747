.fingerprint {
  -webkit-transition: all 1s ease !important;
  -o-transition: all 1s ease !important;
  -ms-transition: all 1s ease !important;
  transition: all 1s ease !important;
  font-size: 45px !important;
}

.fingerprint:hover {
  transform: scale(1.1);
}

.hvr-left {
  -webkit-transition: all 500ms ease !important;
  -o-transition: all 500ms ease !important;
  -ms-transition: all 500ms ease !important;
  transition: all 500ms ease !important;
}

.hvr-left:hover {
  transform: translateX(3px);
}

.logo:hover {
  transform: translateX(3px);
  opacity: 0.7;
}

.logo {
  -webkit-transition: all 500ms ease !important;
  -o-transition: all 500ms ease !important;
  -ms-transition: all 500ms ease !important;
  transition: all 500ms ease !important;
}

/* RESPONSIVE NAVBAR */

.navItems {
  display: block;
}

.menuIcon {
  display: none !important;
}

@media screen and (max-width: 650px) {
  .navItems {
    display: none;
  }
  .menuIcon {
    display: inline-flex !important;
  }
}
